<template>
	<div class="table">
		<el-table
			border
			ref="multipleTable"
			:data="tableData"
			:header-cell-style="{ 'text-align': 'center' }"
			:cell-style="{ 'text-align': 'center' }"
			tooltip-effect="dark"
			style="width: 100%"
			height="60vh"
			@selection-change="handleSelectionChange"
		>
			<el-table-column type="selection" width="55"> </el-table-column>

			<el-table-column prop="id" label="id" width="80px"> </el-table-column>
			<el-table-column prop="title" label="活动名称"> </el-table-column>
			<el-table-column label="类型">
				<template slot-scope="scope">
					<span v-if="scope.row.type == 5">线上活动</span>
					<span v-if="scope.row.type == 6">线下活动</span>
				</template>
			</el-table-column>

			<el-table-column prop="start_time" label="活动开始时间">
			</el-table-column>
			<el-table-column prop="end_time" label="活动结束时间"></el-table-column>

			<el-table-column fixed="right" label="操作" width="190">
				<template slot-scope="scope">
					<span
						class="pointer margin_left_10"
						@click="cancleRecommend(scope.row)"
						>取消推荐</span
					>
				</template>
			</el-table-column>
		</el-table>
	</div>
</template>

<script>
import { recommendactivity } from "@/api/article";
import { status } from "nprogress";

export default {
	props: ["tableData"],
	components: {},
	name: "",
	data() {
		return {
			value: true,
			title: "",
		};
	},
	methods: {
		cancleRecommend(row) {
			this.$confirm("此操作将将取消推荐该条活动, 是否继续?", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
			})
				.then(() => {
					recommendactivity({ id: row.id, status: 0 }).then((res) => {
						if (res.code == 200) {
							this.$message({
								type: "success",
								message: "取消推荐成功!",
							});
							this.$parent.$parent.getDataList();
						}
					});
				})
				.catch(() => {
					this.$message({
						type: "info",
						message: "已取消操作",
					});
				});
		},
		toggleSelection(rows) {
			if (rows) {
				rows.forEach((row) => {
					this.$refs.multipleTable.toggleRowSelection(row);
				});
			} else {
				this.$refs.multipleTable.clearSelection();
			}
		},
		handleSelectionChange(val) {
			this.multipleSelection = val;
		},
	},
};
</script>

<style scoped></style>
